<template>
  <div class="">
    <!--本页切换列表-->
    <!-- <div v-show="!dialogFormVisible"> -->
      <div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
        <div class="tableConTable" v-loading="loading">
          <div class="tableConTop">
            <el-row>
              <el-col :span="5" class="tableConTopLeft">
                <h3>
                  <span class="tct_tit">{{$t('i18nn_41d2148209b5edc8')}}</span>
									<span v-if="!isEdit">(仅预览)</span>
                  <el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
                  	<el-button type="warning" circle icon="el-icon-download" size="mini"
                  		@click="exportExcel()"></el-button>
                  </el-tooltip>
                </h3>
              </el-col>
              <el-col :span="19" class="tableConTopRig">
                <!-- <div style="padding: 10px;"> -->
                <el-button type="success" @click="openDioalog()" size="small" icon="el-icon-plus" v-if="isEdit">
									{{$t('i18nn_24c8f46012a25c89')}}
								</el-button>
                	
                <!-- </div> -->
                <el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()"></el-button>
              </el-col>
            </el-row>
          </div>

          <div>
            <div class="filterCon" style="">
              <!-- <el-row type="flex" justify="start"> -->
                <!-- <el-col :span="24"> -->
							<div class="filterBarList">
								<!-- <el-radio-group v-model="filterData.status" size="medium" @change="initData()">
									<el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">{{ item.label }}</el-radio-button>
								</el-radio-group> -->
								<!-- <el-tabs  v-model="filterData.status" @tab-click="initData">
								  <el-tab-pane :label="item.label" :name="item.value" v-for="item in selectOption.statusList" :key="item.value"></el-tab-pane>
								</el-tabs> -->
								
								<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
								  <el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">{{item.label}}</el-menu-item>
								</el-menu>
							</div>
								
								
                  <ul class="filterConList">
                    <!-- <li>
                      <span>{{$t('6cdece641436d7ab')}}</span>
                      <el-select filterable clearable v-model="filterData.plStatus" :placeholder="$t('2ad108ab2c560530')" size="small" style="width: 100px;" @change="initData()">
                        <el-option v-for="item in selectOption.plStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                      </el-select>
                    </li> -->
                    <li>
                      <!-- <span>userId</span> -->
                      <!-- <span>
												{{filterData.userName}}
											</span>
											<el-button type="success" size="small" icon="el-icon-search" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button> -->
											
											<span class="red-required">{{ $t('hytxs0000060') }}</span>
											<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>
											<!-- <el-input
											  type="text"
											  v-model="filterData.userId"
											  size="small"
											  clearable
											  @keyup.enter.native="initData()"
											  maxlength="50"
											  :placeholder="$t('hytxs0000001')"
											  style="width: 180px;"
											/> -->
											
											<!-- <el-input disabled :placeholder="$t('hytxs0000001')" v-model="filterData.userId" clearable @keyup.enter.native="initData()" class="">
											   
													<el-button type="success" slot="prepend" size="small" icon="el-icon-search" @click="openWhCustomerSelData($event)">{{$t('i18nn_b83597729ab29694')}}</el-button>
													
											    <el-button slot="append" icon="el-icon-search" @click="initData()">{{$t('i18nn_cefda9657773f55f')}}</el-button>
											  </el-input> -->
												
										</li>
										<!-- <li v-if="filterData.userId">
											<span>{{$t('6cdece641436d7ab')}}</span>
											<el-radio-group v-model="filterData.status" size="medium" @change="initData()">
											  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
													{{item.label}}
												</el-radio-button>
											</el-radio-group>
										</li> -->
										<!-- <li v-if="tableData.length<=0">
											<el-button icon="el-icon-s-promotion" size="small" type="primary" @click="createPrepaidAccount()">{{$t('i18nn_a9320232a40b10dc')}}</el-button>
										</li> -->
                    <!-- <li>
                      <span>{{$t('i18nn_49504f74aad443ce')}}</span>
                      <el-input
                        type="text"
                        v-model="filterData.accountName"
                        size="small"
                        clearable
                        @keyup.enter.native="initData()"
                        maxlength="50"
                        :placeholder="$t('hytxs0000001')"
                        style="width: 180px;"
                      />
                    </li> -->
										<!-- <li><el-button icon="el-icon-search" size="small" type="primary" @click="serAll()">{{$t('i18nn_10d660564497918d')}}</el-button></li> -->
										
                    <li><el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button></li>
                  </ul>
									
									
                  <!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>
                  </el-select> -->
                <!-- </el-col> -->
                <!-- <el-col :span="5">
                  <span>{{$t('i18nn_b9cf6fa157d27973')}}</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" :placeholder="$t('i18nn_b9cf6fa157d27973')" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
                <!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">{{$t('9eefb7335988c499')}}</el-button></el-col> -->
                <!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
              <!-- </el-row> -->
            </div>
						
          </div>

          <el-table
            id="out-table2"
            ref="multipleTable"
            :data="tableData"
            stripe
            :border="true"
            :height="$store.state.tableMaxHeight4"
            @row-click="handleCurrentChange"
            @selection-change="handleSelectionChange"
            style="width: 100%"
            size="small"
          >
            <el-table-column type="index" :index="$Utils.tableIndex(pagination)"  fixed="left" width="50" align="center" :label="$t('7b1c2b1adc920d9c')"></el-table-column>
            <!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

            <el-table-column prop="verfiyStatusName" :label="$t('Storage.packing_list.status')">
              <template slot-scope="scope">
                <el-tag type="" v-if="'0' == scope.row.verfiyStatus">{{ scope.row.verfiyStatusName }}</el-tag>
                <el-tag type="success" v-else-if="'10' == scope.row.verfiyStatus">{{ scope.row.verfiyStatusName }}</el-tag>
                <el-tag type="warning" v-else-if="'20' == scope.row.verfiyStatus">{{ scope.row.verfiyStatusName }}</el-tag>
                <el-tag type="info" v-else>{{ scope.row.verfiyStatusName }}</el-tag>
              </template>
            </el-table-column>
            
            <el-table-column prop="amt" :label="$t('i18nn_a06e84801d6582be')"></el-table-column>
            
            <el-table-column prop="evidenceList" :label="$t('Storage.packing_list.attachment')" class="">
                <template slot-scope="scope">
                  <div>
                    <div v-for="(item,index) in scope.row.evidenceList" :key="index">
                      <!-- <el-link type="primary" target="_blank" :href="item.url">{{item.fileName}}</el-link> -->
                    
											<el-image style="width: 80px; height: 80px" :z-index="9999" :fit="'contain'" :src="item.url"
												:preview-src-list="scope.row.evidenceList.map(itemPre=>{ return itemPre.url})">
												<div slot="error" class="image-slot">
													<i class="el-icon-document"></i>
													<!-- {{file.name}} -->
												</div>
											</el-image>
											<span>
												{{item.fileName}}
											</span>
										</div>
                    <!-- <div v-if="scope.row.evidenceList">
                      <el-link type="primary">...<span>{{$t('i18nn_73bbf51487ec69e9')}}</span><span>{{scope.row.evidenceList.length}}</span><span>{{$t('i18nn_7f18cb0ceb569eb0')}}</span></el-link>
                    </div> -->
                  </div>
                </template>
            </el-table-column>
            
            <el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
            	<template slot-scope="scope">
            		<el-popover placement="top"
            	    trigger="hover"
            	    ><div class="pre-text">{{ scope.row.remark }}</div>
            	    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
            	  </el-popover>
            	</template>
            </el-table-column>
            <!-- <el-table-column prop="updateTime" :label="$t('i18nn_df8b6d3f1ea2753f')"></el-table-column> -->

            <!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

            <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column>

            <el-table-column :label="$t('93f5ca01b006206c')" width="220px" fixed="right" v-if="isEdit">
              <template slot-scope="scope">
                <!-- <el-button @click="openEdit($event,scope.row)" type="warning" size="mini" icon="el-icon-edit-outline">{{$t('6267f3aedf895209')}}</el-button> -->
            		
            		<el-button v-if="'10'==scope.row.verfiyStatus" @click="payAction($event,scope.row)" type="success" size="mini" icon="el-icon-bank-card">{{$t('i18nn_993c4e2a71557fe7')}}</el-button>
            		
                <!-- <el-button type="danger" size="mini" icon="el-icon-minus" @click="delAction($event, scope.row)" v-if="'2'!=scope.row.opStatus">{{$t('e33c9b93c36fd250')}}</el-button> -->
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini" icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>
								
                <!-- <el-button @click="completeAction($event, scope.row)" type="success" size="mini" icon="el-icon-finished" v-if="'2'!=scope.row.opStatus">{{$t('25e03120b83ee4ed')}}</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="tableConPagination"><hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination></div>
      </div>
    <!-- </div> -->
    
		<WhRechargeRecordAdd :openTime="openTime" :openRow="openRow" :userId="openUserId" @postSuccess="getPageData()"></WhRechargeRecordAdd>
		
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhVerifiedRechargeRec+'/'+filterData.userId" :expHttpFilter="pageFilterData()"></whExcelCustom>
		
  </div>
</template>
<script>
// import WhCustomer from '@/components/WarehouseCenter/WhCustomer.vue';
import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
import WhRechargeRecordAdd from '@/components/WarehouseCenter2/AccountMana/WhRechargeRecordAdd.vue';

export default {
	props: {
		isEdit: {
			default: function() {
			  return false;
			},
			type: Boolean
		},
		// row: {}
	},
  components: {
    // WhCustomer
		cusSelFuzzy,
		WhRechargeRecordAdd,
		whExcelCustom
  },
  data() {
    return {
			//excel导出
			excelOpenTime: '',
			excelHead: [],
			expExcelData: [],
			excelName: "",
			excelOption: {},
			
      dialogFormVisible: false,
      dialogFormVisibleMsg: '',
			
			openTime:"",
			openRow:{},
			openUserId:"",
      
      selectOption: {
        statusList: [
          {
            value: '',
            label: this.$t('16853bda54120bf1')
          },
          {
            value: '10',
            label: this.$t('7e14d2cd3996dcd2')
          },
          {
            value: '20',
            label: this.$t('i18nn_5c9f3500bc7fc4c2')
          }
          
        ]
      },
      
      //表格数据
      //loading,表格数据
      loading: false,
		loading_load: false,
      //表格数据
      tableData: [],
      //分页数据
      pagination: this.$Utils.defaultPagination(),
      //详情数据
      loading_det: false,
      

    //   form: {
    //     id: null,

    //     userId: '', //标题',
    //     useAmt: '', //this.$t('i18nn_ab6ea90b9164b20a'),
    //     remark:'',
        
    //   },
      
    //   formRules: {
    //     userId: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'blur' }],
				// useAmt: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'change' }]
    //   },
      //查询，排序方式
      filterData: {
        orderBy: 'id_', //排序字段
        sortAsc: 'desc', //desc降序，asc升序
				
				status:'',
        userId: '',
				userName:''
      }
    };
  },
	activated(){
		this.initData();
	},
  //创建时
  created() {
    
  },
  //编译挂载前
  mounted() {
    // console.log("mounted");
  },
  methods: {
    initData() {
			if(!this.filterData.userId){
				this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'))
				return;
			}
      this.pagination.current_page = 1;
      this.currentSel = {};
      this.multipleSelection = [];
      this.getPageData();
      // this.getDicData();
    },
		handleSelectStatus(key, keyPath) {
		  // console.log(key, keyPath);
			this.filterData.status = key;
			this.initData();
		},
		
		//导出 excel
		exportExcel() {
		
			let columns = [{
					title: this.$t('Storage.packing_list.status'),
					key: 'verfiyStatusName'
				},
				{
					title: this.$t('i18nn_a06e84801d6582be'),
					key: 'amt'
				},
				{
					title: this.$t('Storage.packing_list.attachment'),
					key: 'evidenceList',
					types: 'custom',
					formatter: function(row) {
						if (row.evidenceList && row.evidenceList.length>0) {
							return row.evidenceList.map(item=>{
								 return item.fileName;
							}).join(',');
						}
					}
				},
				
				{
					title: this.$t('15b3627faddccb1d'),
					key: 'remark',
				},
				{
					title: this.$t('i18nn_726c51702f70c486'),
					key: 'createTime',
				},
				{
					title: 'userId',
					key: 'userId'
				},
			];
			// let Data = this.tableData;
		
			this.expExcelData = this.tableData;
			this.excelHead = columns;
			this.excelName = 'WhRechargeRecCom';
			this.excelOption = {
				height: 20
			};
			this.excelOpenTime = new Date().getTime();
		
			// excelUtilsNew.exportExcel(columns, Data, 'WhAccountRec',{height:20});
		},
		
		changCus(data) {
			console.log('changCus', data);
			
			this.filterData.userId= data.userId;
			// this.filterData.userName = data.companyName;
			this.initData();
		},
		//生成预付账户
		// createPrepaidAccount(){
		// 	if(this.filterData.userId){
		// 		this.createPrepaidAccountData(this.filterData.userId);
		// 	} else {
		// 		this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
		// 	}
		// },
		// createPrepaidAccountData(userId){
		// 	// let formData = Object.assign({}, this.form);
		// 	// formData.id = null;
		// 	this.postData(this.$urlConfig.WhAccountAdd+'/'+userId, {}, () => {
		// 	  // this.dialogFormVisible = false;
		// 	  this.getPageData();
		// 	  // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
		// 	  //   type: 'success',
		// 	  //   
		// 	  // });
		// 	  this.$message.success('生成预付账户成功！');
		// 	});
		// },
    //状态
    // formatterEnable(row, column){
    //   if('1'===row.enable){
    //     return this.$t('i18nn_889b3ef0590d9f54');
    //   } else if('0'===row.enable){
    //     return this.$t('4e9fc68608c60999');
    //   } else {
    //     return row.enable;
    //   }
    // },
    //查询数据
    // serPageData() {
    //   this.pagination.current_page = 1;
    //   this.getPageData();
    // },
    // formatterDate(row, column) {
    //   if (row.invalidDate == null) {
    //     return '';
    //   }
    //   return row.invalidDate.substring(0, 10);
    // },
		
		//分页的筛选项数据
		pageFilterData() {
			// let startTime = '';
			// let endTime = '';
			// if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
			// 	startTime = this.filterData.daterange[0];
			// 	endTime = this.filterData.daterange[1];
			// } else {
			// 	// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			// }
		
			return {
				verfiyStatus: this.filterData.status? this.filterData.status:null,
				userId: this.filterData.userId ? this.filterData.userId : null,
				// "accountName": this.filterData.accountName? this.filterData.accountName:null,
				// "goodsType": this.queryParamObj.goodsType
			};
		},
		
    //请求分页数据
    getPageData() {
      // let _this = this;
			
			let filterData = Object.assign({
				offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				limit: this.pagination.page_size, //当前页显示数目
			}, this.pageFilterData())
			
      this.loading_load = true;
      this.$http
        .put(this.$urlConfig.WhVerifiedRechargeRec+'/'+this.filterData.userId, filterData)
        .then(({ data }) => {
          
          this.loading_load = false;
          if (200 == data.code) {
            //表格显示数据
            this.tableData = data.rows;
            //当前数据总条数
            this.pagination.total = parseInt(data.total);
            //当前页数
            // this.pagination.current_page = parseInt(data.current);
            //当前页条数
            // this.pagination.page_size = parseInt(data.size);
            // this.$message.success('商家账单列表，请求成功');
          } else {
            this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
          }
        })
        .catch(error => {
          console.log(error);
          this.$message.error(this.$t('hytxs0000030'));
          this.loading_load = false;
        });
    },
		
		//打开新增编辑，弹窗
		openDioalog(formParm) {
			this.openTime = new Date().getTime();
			this.openRow = {};
			this.openUserId = this.filterData.userId;
		},
		//打开编辑
		openEdit(event, row, index) {
			event.stopPropagation();
			// this.openDioalog(row, this.$t('FormMsg.Edit'));
			this.openTime = new Date().getTime();
			this.openRow = row;
			this.openUserId = this.filterData.userId;
		},
		
		//删除
		delAction(event, row) {
			this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('204ffab8a6e01870'),
				// cancelButtonText: this.$t('4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					// this.$message({
					//   type: 'success',
					//   message: '删除成功!'
					// });
					this.delDataAction(event, row);
				})
				.catch(() => {
					// this.$message({
					//   type: 'info',
					//   message: this.$t('i18nn_2e58cb9b52e2a214')
					// });
				});
		},
		//删除
		delDataAction(event, row) {
			event.stopPropagation();
			console.log('delDataAction', row);
		
			this.postData(this.$urlConfig.WhRechargeVerifyDelete + '/' + row.id, {}, ()=>{
				this.$message({
				  type: 'success',
				  message: this.$t('i18nn_de017dafc266aa03')
				});
				this.initData();
			});
		},
		
    //打开二级分配弹窗
    // openDetModal(row) {
    //   this.dialogDetVisible = true;
    //   this.detData = [];
    //   this.getDetData(row.id);
    // },
    // getDetListData(opNo) {
    //   this.loading_det = true;
    //   this.$http.put(this.$urlConfig.WhFinanceRecPageList, {
    //       offset: 0,
    //       limit: 100,
    //       "opNo": opNo
    //     })
    //     .then(({ data }) => {
    //       this.loading_det = false;
    //       if (200 == data.code) {
    //         //表格显示数据
    //         // this.detData = data.rows;
    //         this.form.finRecords = data.rows;
    //       } else {
    //         this.$message.warning(data.msg ? data.msg : this.$t('i18nn_9c0ace2b5d32f74c'));
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$message.error('请求数据有误！');
    //       this.loading_det = false;
    //     });
    // },
    //新增
    // addLe2() {
    //   this.form.finRecords.push({
    //     finName: '',
    //     finAmt: '0',
    //     remark:''
    //   });
    // },
    // //删除
    // delLe2(index) {
    //   this.form.finRecords.splice(index, 1);
    // },
    //弹窗更新
    // updateVisible(val) {
    //   this.dialogFormVisible = val;
    // },
    // openDioalog(formParm, msg) {
    //   console.log(formParm);
    //   this.dialogFormVisibleMsg = msg;
    //   this.dialogFormVisible = true;
    //   // let form = Object.assign({}, formParm);
    //   // console.log(form);
    //   // 重置
    //   this.resetForm('form');
    //   // if (null === formParm) {
    //   //   //新增
    //   //   // 重置
    //   //   // this.resetForm('form');
    //   // } else {
    //     //修改
    //     // 重置
    //     // this.resetForm('form');
    //     let form = Object.assign({}, formParm);
    //     this.form = Object.assign({}, form);
				// this.form.oldUseAmt = formParm.useAmt;
				// this.form.useAmt = 0;
				// // this.form = {"id":form.id,"userId":form.userId,"useAmt":0};
    //     // this.getDetListData(form.opNo);
    //   // }
    // },
    //打开编辑
  //   openEdit(event,row){
  //     event.stopPropagation();
  //     this.openDioalog(row, this.$t('6267f3aedf895209'))
  //   },
		// //充值
		//充值
		payAction(event,row){
		  event.stopPropagation();
		  // this.openDioalog(row, this.$t('6267f3aedf895209'))
			this.$confirm(this.$t('i18nn_b8ace7701a8138f9')+row.amt+this.$t('i18nn_7495b1a692c585b5')+'？', this.$t('tips.tipsTitle'), {
				// confirmButtonText: this.$t('204ffab8a6e01870'),
				// cancelButtonText: this.$t('4b3340f7872b411f'),
				type: 'warning'
			})
				.then(() => {
					this.postData(this.$urlConfig.WhVerifiedRecharge+'/'+row.id, {}, () => {
					  // this.dialogFormVisible = false;
					  this.getPageData();
					  // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
					  //   type: 'success',
					  //   
					  // });
					  this.$message.success(this.$t('i18nn_ebe414c3e59312d7'));
					});
				})
				.catch(() => {
					
				});
		},

    //删除，分成模板
    // deleteTem() {
    //   if (this.currentSel.confId == null) {
    //     this.$alert('请选择需要删除的数据!', this.$t('cc62f4bf31d661e3'));
    //     return;
    //   }

    //   // this.invalidDateForm.confId = this.currentSel.confId;
    //   // let formData = Object.assign({}, this.invalidDateForm);
    //   // // formData.id = this.currentSel.id;
    //   // this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
    // },

    // openDioalogInvalidDate() {
    //   console.log(this.currentSel);
    //   if (this.currentSel.confId == null) {
    //     this.$alert('请选择需要注销的数据!', this.$t('cc62f4bf31d661e3'));
    //     return;
    //   }
    //   this.dialogFormInvalidDate = true;
    //   let form = Object.assign({}, this.currentSel);
    //   // console.log(form);

    //   // 重置
    //   this.resetForm('invalidDateForm');
    //   this.invalidDateForm.confId = form.confId;
    //   this.invalidDateForm.confName = form.confName;
    //   this.invalidDateForm.effectiveDate = form.effectiveDate;
    //   // if(!!form.invalidDate){
    //   //   this.invalidDateForm.invalidDate = form.invalidDate;
    //   // } else {
    //   //   this.invalidDateForm.invalidDate = "";
    //   // }
    // },
    resetForm(formName) {
      console.log(formName);
      this[formName] = {};

      // if ('form' === formName) {
      //   this[formName]['finRecords'] = [];
      // }
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // this.form.remark = ""; //  备注//非必填
      } else {
        console.log('this.$refs[formName]', this.$refs[formName]);
      }
    },
    //编辑时，格式化list
    // fomatterConfigDetList(list) {
    //   list.forEach(function(item, index, array) {
    //     item.id = null;
    //   });
    //   return list;
    // },
    //提交信息
    submitForm(formName, type) {
      //验证省市县必输入
      this.$refs[formName].validate(valid => {
        // if (true) {
        if (valid) {
          // alert('submit!');

          //浅拷贝、对象属性的合并
          // this.form = Object.assign({},form);
          // if ('1' === type) {
            //新增
            let formData = Object.assign({}, this.form);
            // formData.id = null;
            this.postData(this.$urlConfig.WhAccountRecharge, formData, () => {
              this.dialogFormVisible = false;
              this.getPageData();
              // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
              //   type: 'success',
              //   
              // });
              this.$message.success(this.$t('i18nn_994cb374e601fab3'));
            });
          // } 
					// else if ('2' === type) {
     //        //注销
     //        // let formData = Object.assign({}, this.invalidDateForm);
     //        // formData.id = this.currentSel.id;
     //        // this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
     //      } else if ('3' === type) {
     //        //编辑
     //        let formData = Object.assign({}, this.form);
     //        // formData.id = null;
     //        this.postData(this.$urlConfig.WhFinanceCreate, formData, () => {
     //          this.dialogFormVisible = false;
     //          this.getPageData();
     //          // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
     //          //   type: 'success',
     //          //   
     //          // });
     //          this.$message.success('编辑成功！');
     //        });
     //      }
        } else {
          console.log('error submit!!');
          this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            
          });
          return false;
        }
      });
    },
    // getDicData() {
    //   this.$http
    //     .get(this.$urlConfig.HyMatchintFeeConfQueryDic)
    //     .then(({ data }) => {
    //       console.log(data);
    //       if (200 == data.code) {
    //         this.selectOption = data.data;
    //       } else {
    //         console.log('下拉列表数据请求失败!');
    //       }
    //       this.loading_load = false;
    //     })
    //     .catch(error => {
    //       console.log(error);
    //       console.log('更新特定数据，请求失败');
    //       this.loading_load = false;
    //     });
    // },
    // handleCurrentChange(val) {
    //   console.log('handleCurrentChange', val);
    //   // this.tableData.map(item => {
    //   //   if (item.confId === val.confId) {
    //   //     item.selected = true;
    //   //   } else {
    //   //     item.selected = false;
    //   //   }
    //   // });
    //   // this.currentSel = val;
    //   // this.$refs.multipleTable.toggleRowSelection(this.tableData3[2],true);
    // },
    //选择行
    handleCurrentChange(row, event, column) {
      // this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
    },
    //多选
    handleSelectionChange(val) {
      console.log(val);
      // this.multipleSelection = val;
    },

    //提交数据
    postData(url, formData, callback) {
      // let _this = this;
      this.loading = true;

      this.$http
        .put(url, formData)
        .then(({ data }) => {
          console.log(this.$t('i18nn_bc868e024b80d2e3'));
          console.log(data);
          
          this.loading = false;
          if (200 == data.code) {
            callback();
          } else {
            if (!data.msg) {
              data.msg = this.$t('dbe331ab679cd67f');
            }
            this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
              type: 'warning',
              
            });
          }
        })
        .catch(error => {
          console.log(error);
          console.log(this.$t('i18nn_a7d2e953195a5588'));
          this.loading = false;
          this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
            type: 'warning',
            
          });
        });
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
// /deep/ .el-table__body {
//   .el-input {
//     input {
//       padding-left: 5px;
//       padding-right: 5px;
//     }
//   }
//   // .el-input-number {
//   //   width: 80px;
//   //   .el-input__inner {
//   //     text-align: left;
//   //   }
//   // }
// }
</style>
